import { TimerService } from '@/services/stopwatch/timer.service'

export const StopwatchService = {
  getTime (client, game) {
    return TimerService.get(`api/stopwatch/get-time/${client}/${game}`)
  },
  setTime (client, game, data) {
    return TimerService.put(`api/stopwatch/set-time/${client}/${game}`, data)
  },
  clearTime (client, game) {
    return TimerService.delete(`api/stopwatch/clear-time/${client}/${game}`)
  }
}
