const fields = [
  {
    key: 'code',
    type: 'textarea',
    rows: 10,
    templateOptions: {
      attrs: {
        rows: 5
      },
      label: 'embedVideoCode'
    }
  }
]

export default fields
