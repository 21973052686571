<template>
  <div class="d-inline-block">

    <button @click="openQrCodeModal()" class="btn btn-sm btn-info"> <i class="fa fa-clock-o"></i></button>
    <modal :name="`qr-modal-${componentId}`" :adaptive="true" height="auto">
      <div class="qr-wrapper">
        <div class="p-3">
        <h3 class="text-center">QR code for stopwatch</h3>
          <div class="d-flex align-center justify-content-center mb-3">
              <div :id="componentId"></div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import QRCodeStyling from 'qr-code-styling'

export default {
  props: {
    name: {
      type: String,
      default: 'qr-code'
    },
    link: {
      type: String,
      default: ''
    }
  },
  data (vm) {
    return {
      componentId: vm._uid,
      qrCode: null,
      size: 300
    }
  },
  watch: {
    size: 'changeSize'
  },
  methods: {
    openQrCodeModal () {
      this.$modal.show('qr-modal-' + this.componentId)
      setTimeout(() => {
        this.bootQRcode()
      })
    },
    changeSize () {
      this.bootQRcode()
    },
    bootQRcode () {
      if (this.qrCode) {
        this.qrCode._container.getElementsByTagName('svg')[0].remove()
      }

      const qrCode = new QRCodeStyling({
        width: this.size,
        height: this.size,
        type: 'svg',
        data: this.link,
        image: `/qr-${this.size}.png`,
        dotsOptions: {
          color: '#008078',
          type: 'extra-rounded'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          imageSize: 0.55,
          margin: 4
        },
        qrOptions: {
          // errorCorrectionLevel: 'M'
        }
      })

      this.qrCode = qrCode

      qrCode.append(document.getElementById(this.componentId))
    },
    download () {
      this.qrCode.download({ name: `qr-${this.name}-${this.size}`, extension: 'png' })
    }
  }
}
</script>
<style>
 .qr-button {
   display: block;
   border: 0;
   background: none;
   cursor: pointer;
 }
</style>
