import Ably from 'ably/callbacks'

const ably = new Ably.Realtime(process.env.VUE_APP_ABLY_KEY)

function getPublicChannel (name) {
  return ably.channels.get('public:' + name)
}

function unsubscribePublicChannel (name) {
  const channel = getPublicChannel(name)
  channel.unsubscribe()
  channel.detach()
}
export { ably, getPublicChannel, unsubscribePublicChannel }
