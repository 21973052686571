import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { auth } from './auth.module'
import { main } from '@/store/main'
import VueI18n from 'vue-i18n'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    main
  },
  state: {
    availableLanguages: [],
    language: ''
  },
  mutations: {},
  getters: {},
  actions: {
    getAvailableLanguages ({ state }) {
      state.availableLanguages = require('../../../lm-locales/config/languages.json')
    },
    initLanguage (store) {
      let lang

      if (localStorage.getItem('locale')) {
        lang = localStorage.getItem('locale')
      } else {
        lang = 'en'
      }

      store.dispatch('setLanguage', lang)
    },
    setLanguage ({ state }, lang) {
      state.language = lang
      VueI18n.locale = lang
      localStorage.setItem('locale', lang)
    }
  },
  plugins: [new VuexPersistence({
    modules: ['main']
  }).plugin]
})

export default store
export const useStore = () => store
