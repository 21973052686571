var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"VueCarousel"},[_c('div',{ref:"VueCarousel-wrapper",staticClass:"VueCarousel-wrapper"},[_c('div',{ref:"VueCarousel-inner",staticClass:"VueCarousel-inner",style:({
        'transform': `translate(${_vm.currentOffset}px, 0)`,
        'transition': _vm.dragging ? 'none' : _vm.transitionStyle,
        'ms-flex-preferred-size': `${_vm.slideWidth}px`,
        'webkit-flex-basis': `${_vm.slideWidth}px`,
        'flex-basis': `${_vm.slideWidth}px`,
        'visibility': _vm.slideWidth ? 'visible' : 'hidden',
        'padding-left': `${_vm.padding}px`,
        'padding-right': `${_vm.padding}px`
      }),attrs:{"role":"listbox"}},[_vm._t("default")],2)]),(_vm.paginationEnabled && _vm.pageCount > 0)?_c('pagination',{on:{"paginationclick":function($event){return _vm.goToPage($event, 'pagination')}}}):_vm._e(),(_vm.navigationEnabled)?_c('navigation',{attrs:{"clickTargetSize":_vm.navigationClickTargetSize,"nextLabel":_vm.navigationNextLabel,"prevLabel":_vm.navigationPrevLabel},on:{"navigationclick":_vm.handleNavigation}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }