<template>
  <button @click="clipboard" class="btn btn-sm btn-info"><i :class="icon"></i></button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'fa fa-clipboard'
    }
  },
  methods: {
    clipboard () {
      this.$copyText(this.text).then(() => {
        // this.$notify('Copied to clipboard', 'success')
        this.$toast.fire({
          title: 'Copied to clipboard',
          icon: 'success'
        })
      })
    }
  }
}

</script>
