import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/auth/Login.vue'
import { TokenService } from '@/services/storage.service'
import store from '@/store'
import Default from '../layouts/Default'
import Auth from '../layouts/Auth'
import PreGame from '../views/PreGame'
import Live from '../views/live/Live.vue'
import Archive from '../views/Archive'
import ApiService from '@/services/api.service'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {
            public: true, // Allow access to even if not logged in
            onlyWhenLoggedOut: true
          }
        }
      ]
    },
    {
      path: '/',
      name: 'admin',
      component: Default,
      children: [
        {
          path: '/',
          name: 'preGame',
          component: PreGame
        },
        {
          path: '/live',
          name: 'Live',
          component: Live
        },
        {
          path: '/archive',
          name: 'archive',
          component: Archive
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.query.code) {
    TokenService.saveToken(to.query.code)
  }

  if (TokenService.getToken()) {
    ApiService.setHeader()
  }

  // eslint-disable-next-line
  jQuery('.modal').modal('hide')
  store.commit('main/loading')
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken()
  if (!isPublic && !loggedIn) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath }
      // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next()
})

router.afterEach((to, from) => {
  store.commit('main/loaded')
})

export default router
