<template>
  <div class="block">
      <div class="block-header" :class="headerType" v-if="title || subtitle || $slots.options">
          <h3 class="block-title" @click="toggleCollapse"> {{title}} <small v-if="subtitle">{{subtitle}}</small></h3>
          <div class="block-options">
              <slot name="options"/>
            <button v-if="$slots.default && collapsable" type="button" @click="toggleCollapse()" class="btn btn-sm ml-md-3"><i :class="{'fa-chevron-down': isCollapsed, 'fa-chevron-up': !isCollapsed}" class="fa"></i></button>
          </div>
      </div>
      <div v-show="$slots.default && isCollapsed" class="block-content">
          <slot/>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String
    },
    headerType: {
      type: String,
      default: 'block-header-default'
    },
    showContent: {
      default: false,
      type: Boolean
    },
    collapsable: {
      type: Boolean,
      default: true
    },
    initCollapse: {
      type: Boolean,
      default: true
    },
    saveCollapse: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  },
  mounted () {
    this.isCollapsed = this.initCollapse

    if (this.saveCollapse) {
      const collapseId = localStorage.getItem(this.saveCollapse)

      if (collapseId) {
        this.isCollapsed = collapseId === 'true'
      } else {
        localStorage.setItem(this.saveCollapse, this.isCollapsed)
      }
    }
  },
  methods: {
    toggleCollapse () {
      if (!this.collapsable) {
        return
      }
      this.isCollapsed = !this.isCollapsed
      if (this.saveCollapse) {
        localStorage.setItem(this.saveCollapse, this.isCollapsed)
      }
    }
  }
}
</script>
