export const debtTypes = [
  'PUNISHMENT',
  'PARTICIPATION',
  'OTHER'
]
export const addDebtFields = [
  {
    key: 'amount',
    type: 'input',
    templateOptions: {
      attr: {
        type: 'number'
      },
      label: 'amount'
    }
  },
  {
    key: 'type',
    type: 'ntm-select',
    multiple: false,
    options: debtTypes,
    templateOptions: {
      translated: true,
      label: 'type'
    }
  },
  {
    key: 'desc',
    type: 'textarea',
    templateOptions: {
      label: 'description'
    }
  }
]
