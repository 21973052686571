<template>
  <div class="le-content-loader">
    <div class="le-content-loader-inner">
      <div class="le-content-loader-spinner" />
      <img :src="LeLogo" :alt="LeLogo">
    </div>
  </div>
</template>

<script>
import LeLogo from '@/assets/le.png'

export default {
  name: 'Loader',
  data () {
    return {
      LeLogo
    }
  }
}
</script>

<style>
  .le-content-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
  }

  .le-content-loader-inner {
    width: 140px;
    height: 140px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .le-content-loader-inner img {
    opacity: 0.9;
    width: 70%;
  }

  .le-content-loader-spinner {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 140px;
    height: 140px;
    border: 3px solid rgba(0, 0, 0,.2);
    border-radius: 50%;
    border-top-color: rgba(0, 0, 0,.7);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
</style>
