import ApiService from './api.service'

const GlobalService = {
  config () {
    return ApiService.get('api/v1/game/config')
  },
  searchPlayers (search) {
    return ApiService.get(`api/v1/game/players/search?search=${search}`)
  }
}

export default GlobalService
