<template>
  <div>
    <button type="button" @click="onSelected(action)" v-for="action in options" :key="action.id" :disabled="isPositionSelected(action)" :class="{'disabled': isPositionSelected(action)}"  class="btn btn-success">{{ action.name }}</button>
  </div>
</template>

<script>

import { useLive } from '../../views/live/use/useLive'

export default {
  props: {
    entity: {
      type: Object,
      default: null
    },
    entityType: {
      type: String,
      default: ''
    },
    options: Array,
    default: () => []
  },
  setup () {
    const { autoActions, config } = useLive()

    return { autoActions, config }
  },
  methods: {
    isPositionSelected (action) {
      return (this.autoActions.homeTeam[action.id] === this.entity.uuid || this.autoActions.awayTeam[action.id] === this.entity.uuid)
    },
    onSelected (action) {
      this.$emit('selected', action)
      this.$forceUpdate()
    }
  },
  computed: {
    positionsSelected () {
      return this.autoActions
    },
    isSelected () {
      return this.autoActions
    }
  }
}
</script>
<style>

</style>
