<template>
  <div
    class="timer-toggle"
    :class="{'time-editing': isEditMode, 'active-stopwatch': activeStopwatch}"
  >
    <div v-if="!activeStopwatch" class="edit-buttons-wrapper up d-flex justify-content-between">
      <button
        class="btn btn-info px-2"
        @click="increaseTime(60000)"
      >
        <i class="fa fa-chevron-up"/>
      </button>
      <button
        class="btn btn-info px-2"
        @click="increaseTime(1000)"
      >
        <i class="fa fa-chevron-up"/>
      </button>
    </div>
    <div v-if="activeStopwatch && activeStopwatch?.data?.type === 'timer'" class="edit-buttons-wrapper w-100 up d-flex justify-content-between">
      <button
        class="btn btn-warning px-1 font-s flex-grow-1 eject-timer"
        @click="ejectTimer()"
      >
        Eject timer
      </button>
    </div>
    <div>
      <button
        v-longpress="() => toggleEditMode()"
        @click.right.prevent="toggleEditMode"
        :class="{ 'btn-danger blob': !isRunning && !activeStopwatch, 'btn-success': isRunning && !activeStopwatch, 'btn-info': activeStopwatch }"
        class="btn accept-log"
        type="button"
        @click="toggleTime"
      >
        {{ currentFormattedTime }}
      </button>
    </div>
    <div v-if="!activeStopwatch" class="edit-buttons-wrapper down d-flex justify-content-between">
      <button
        @click="decreaseTime(60000)"
        class="btn btn-info px-2"
      >
        <i class="fa fa-chevron-down"/>
      </button>
      <button
        @click="decreaseTime(1000)"
        class="btn btn-info px-2"
      >
        <i class="fa fa-chevron-down"/>
      </button>
    </div>
  </div>
</template>
<script>
import { useLive } from '../views/live/use/useLive'

export default {
  name: 'LeStopwatch',
  props: {
    startingTimeData: {
      type: Object,
      default: () => null
    },
    isReverse: {
      type: Boolean,
      default: false
    },
    currentPeriod: {
      type: Number,
      default: 1
    },
    periodTime: {
      type: Number,
      default: 600000
    }
  },
  setup () {
    const { timerChange, activeStopwatch, toggleTime, ejectTimer, saveTimeToTimerApi } = useLive()

    return { timerChange, activeStopwatch, toggleTime, ejectTimer, saveTimeToTimerApi }
  },
  watch: {
    seconds: {
      handler () {
        this.saveTimeToTimerApi({ time: this.diffTime, shotClock: 0, isRunning: this.isRunning, isShotClockUpdated: false })
      },
      immediate: false
    }
  },
  data () {
    return {
      editTimeout: false,
      isEditMode: false,
      times: [],
      animateFrame: 0,
      nowTime: 0,
      diffTime: 0,
      shotClockDiffTime: 0,
      startTime: 0,
      startShotClockTime: 0,
      isRunning: false,
      count: 0,
      id: null
    }
  },
  computed: {
    periodMaxTimeOnPeriod () {
      if (this.isReverse) {
        return this.periodTime
      }

      if (this.currentPeriod === 0) {
        return (this.config.periods + 1) * this.periodTime
      }

      return this.periodTime * this.currentPeriod
    },
    periodMinTimeOnPeriod () {
      if (this.isReverse) {
        return 0
      }

      if (this.currentPeriod === 0) {
        return this.config.periods * this.periodTime
      }

      return this.periodTime * (this.currentPeriod - 1)
    },
    minutes: function () {
      return this.zeroPad(Math.floor(this.diffTime / 1000 / 60) % 60)
    },
    seconds: function () {
      return this.zeroPad(Math.floor(this.diffTime / 1000) % 60)
    },
    ceilSeconds: function () {
      return this.zeroPad(Math.floor(this.diffTime / 1000) % 60)
    },
    formattedMinutes: function () {
      return this.zeroPad(this.minutes)
    },
    formattedSeconds: function () {
      return this.zeroPad(this.ceilSeconds)
    },
    milliSeconds: function () {
      return Math.floor(this.diffTime % 1000)
    },
    formattedMilliSeconds: function () {
      return this.milliSeconds.toString().charAt(0)
    },
    currentFormattedTime () {
      return this.formattedMinutes + ':' + this.formattedSeconds + '.' + this.formattedMilliSeconds
    }
  },
  mounted () {
    this.initTime()
  },
  // eslint-disable-next-line vue/order-in-components
  methods: {
    initTimeByPeriod (doUpdate = false) {
      this.diffTime = (this.isReverse ? this.periodMaxTimeOnPeriod : this.periodMinTimeOnPeriod)
      if (doUpdate) {
        this.stopTimer()
      }
    },
    initTime () {
      if ((this.startingTimeData?.time >= this.periodMaxTimeOnPeriod) || (this.startingTimeData?.time <= this.periodMinTimeOnPeriod)) {
        this.initTimeByPeriod()
      } else {
        this.diffTime = this.startingTimeData?.time
      }

      if (this.startingTimeData.isRunning) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
    toggleEditMode () {
      this.isEditMode = !this.isEditMode
    },
    increaseTime (by = 1000) {
      const isRunning = Boolean(this.isRunning)

      let time = this.diffTime + by

      if (time > this.periodMaxTimeOnPeriod) {
        time = this.periodMaxTimeOnPeriod
      }

      if (this.diffTime === time) {
        return
      }

      if (isRunning) {
        this.stopTimer()
      }

      this.diffTime = time

      if (isRunning) {
        this.startTimer()
      }

      this.updateStopwatchTime()
    },
    decreaseTime (by = 1000) {
      const isRunning = Boolean(this.isRunning)

      let time = this.diffTime - by

      if (time < this.periodMinTimeOnPeriod) {
        time = this.periodMinTimeOnPeriod
      }

      if (this.diffTime === time) {
        return
      }

      if (isRunning) {
        this.stopTimer()
      }

      this.diffTime = time

      if (isRunning) {
        this.startTimer()
      }

      this.updateStopwatchTime()
    },
    updateStopwatchTime (time = null) {
      const data = {
        time: time || this.diffTime,
        shotClock: this.shotClockDiffTime,
        isRunning: this.isRunning
      }

      this.timerChange(data)
    },
    setSubtractStartTime (time) {
      const newTime = typeof time !== 'undefined' ? time : 0
      this.startTime = Math.floor((this.isReverse ? -performance.now() : performance.now()) - newTime)
    },
    setSubtractShotClockStartTime (time) {
      const newTime = typeof time !== 'undefined' ? time : 0
      this.startShotClockTime = Math.floor(-performance.now() - newTime)
    },
    startTimer () {
      if (this.isRunning) {
        return
      }

      this.isRunning = true
      this.updateStopwatchTime()
      this.setSubtractStartTime(this.diffTime)
      this.setSubtractShotClockStartTime(this.shotClockDiffTime)
      this.loop()
    },
    stopTimer () {
      this.isRunning = false
      cancelAnimationFrame(this.animateFrame)
      this.updateStopwatchTime()
    },
    loop () {
      if (this.isReverse && (this.diffTime <= 100)) {
        this.diffTime = 0
        this.stopTimer()
        return
      } else if (!this.isReverse && (this.diffTime >= this.periodMaxTimeOnPeriod)) {
        this.diffTime = this.periodMaxTimeOnPeriod
        this.stopTimer()
        return
      }

      if (this.shotClockDiffTime < 100) {
        this.shotClockDiffTime = 0
      } else {
        this.shotClockDiffTime = -this.nowTime - this.startShotClockTime
      }

      this.nowTime = Math.floor(performance.now())
      this.diffTime = (this.isReverse ? -this.nowTime : this.nowTime) - this.startTime

      this.animateFrame = requestAnimationFrame(this.loop)
    },
    zeroPad: function (value, num) {
      const newNum = typeof num !== 'undefined' ? num : 2
      return value.toString().padStart(newNum, '0')
    }
  }
}
</script>
<style>
.timer-toggle {
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: 0.15s;
  z-index: 10;
}

.timer-toggle.time-editing.active-stopwatch {
  bottom: 15px;
}

.timer-toggle.time-editing {
  bottom: 50px;
}

.edit-buttons-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.timer-toggle.time-editing .edit-buttons-wrapper {
  visibility: visible;
  opacity: 1;
}

.edit-buttons-wrapper.up {
  top: -8px;
  transform: translateY(-100%);
}

.edit-buttons-wrapper.down {
  bottom: -8px;
  transform: translateY(100%);
}

.edit-buttons-wrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-buttons-wrapper .eject-timer {
  font-size: 11px;
  word-wrap: anywhere;
  text-wrap: balance;
}

.blob {
  transform: scale(1);
  animation: pulse 1.2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.50);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.50);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
  }
}
</style>
