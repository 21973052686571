/* eslint-disable */
// import env from '@/env'
// import * as VueGoogleMaps from 'vue2-google-maps'
import VueFormly from './formly/vue-formly'
import VueFormlyBootstrap from './formly/formly-bootstrap'
import Datetime from './formly/fields/Datepicker'
import Multiselect from './formly/fields/Multiselect'
import Multicheck from './formly/fields/Multicheck'
import Boolean from './formly/fields/Boolean'
import Upload from './formly/fields/Upload'
import NtmTable from './ntm/ntmTable'
import datePicker from 'vue-ctk-date-time-picker'
import Notify from './alerts/notification'
import NtmList from '@/components/ntm/list/ntmList'
import NtmListItem from '@/components/ntm/list/ntmListItem'
import ntmModal from '@/components/ntm/ntmModal'
import ntmBlock from '@/components/ntm/ntmBlock'
import VueCarousel from '@/components/ntm/carousel'
import VueDraggable from 'vue-draggable'
import PageHeader from './page/PageHeader'
import PageContent from './page/PageContent'
import VueSweetalert2 from 'vue-sweetalert2';
import {VueEditor} from "vue2-editor";
import NtmCrop from "@/components/ntm/ntmCrop";
import ntmSidebar from "@/components/ntm/ntmSidebar";
import vSelect from 'vue-select'
import Swal from 'sweetalert2';
import Vue2TouchEvents from 'vue2-touch-events'
import NtmSelect from './formly/fields/NtmSelect';
import HtmlEditor from './formly/fields/HtmlEditor'
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
import DropdownMenu from '@innologica/vue-dropdown-menu';
import VModal from 'vue-js-modal'
import Debt from "./ntm/Debt.vue";
import VueClipboard from 'vue-clipboard2'
import LeCopyToClipboard from "./le/LeCopyToClipboard.vue";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const RegisterPlugin = {
  install(Vue, options) {
    Vue.use(Vue2TouchEvents, {
      touchHoldTolerance: 400
    })
    VueFormly.addType('datepicker', Datetime)
    VueFormly.addType('ntm-select', Multiselect)
    VueFormly.addType('ntm-v-select', NtmSelect)
    VueFormly.addType('ntm-multicheck', Multicheck)
    VueFormly.addType('upload', Upload)
    VueFormly.addType('boolean', Boolean)
    VueFormly.addType('html', HtmlEditor)
    Vue.use(VueFormly)
    Vue.use(VueFormlyBootstrap)
    Vue.use(VueCarousel)
    Vue.use(VueDraggable)
    Vue.use(VueSweetalert2);
    Vue.use(VueClipboard)
    Vue.component('le-copy-to-clipboard', LeCopyToClipboard)
    Vue.component("context-menu", VueSimpleContextMenu);
    Vue.component('ntm-html-editor', VueEditor)
    Vue.component('ntm-table', NtmTable)
    Vue.component('ntm-crop', NtmCrop)
    Vue.component('ntm-list', NtmList)
    Vue.component('ntm-list-item', NtmListItem)
    Vue.component('page-header', PageHeader)
    Vue.component('page-content', PageContent)
    Vue.component('date-picker', datePicker)
    Vue.component('ntm-modal', ntmModal)
    Vue.component('ntm-block', ntmBlock)
    Vue.component('ntm-sidebar', ntmSidebar)
    Vue.component('v-select', vSelect)
    Vue.component('ntm-dropdown', DropdownMenu)
    Vue.component('debt', Debt)
    Vue.use(VModal)
    // Use Notify
    Vue.use(Notify)
    Vue.prototype.$toast = Toast

    Vue.directive('longpress', {
      bind: function (el, binding, vNode) {
        // Make sure expression provided is a function
        if (typeof binding.value !== 'function') {
          // Fetch name of component
          const compName = vNode.context.name
          // pass warning to console
          let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}' ` }

          console.warn(warn)
        }

        // Define variable
        let pressTimer = null

        // Define funtion handlers
        // Create timeout ( run function after 1s )
        let start = (e) => {

          if (e.type === 'click' && e.button !== 0) {
            return;
          }

          if (pressTimer === null) {
            pressTimer = setTimeout(() => {
              // Run function
              handler()
            }, 500)
          }
        }

        // Cancel Timeout
        let cancel = (e) => {
          // Check if timer has a value or not
          if (pressTimer !== null) {
            clearTimeout(pressTimer)
            pressTimer = null
          }
        }
        // Run Function
        const handler = (e) => {
          binding.value(e)
        }

        // Add Event listeners
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        // Cancel timeouts if this events happen
        el.addEventListener("touchmove", cancel);
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
      }
    })
  }
}

export default RegisterPlugin
