<template>
  <div class="block block-rounded block-link-shadow mb-0" :class="{'bg-danger': debt.type === 'PUNISHMENT', 'bg-info': debt.type === 'PARTICIPATION', 'bg-warning': debt.type === 'OTHER'}" href="javascript:void(0)">
    <div class="block-content block-content-full d-flex align-items-center justify-content-between">
      <dl class="me-3 mb-0">
        <dt class="text-white h3 fw-extrabold mb-0">
          {{ debt.amount }}
        </dt>
        <dd class="text-white fs-sm fw-medium text-muted mb-0">
          {{ $t(`debt.types.${debt.type}`) }}
        </dd>
      </dl>
      <div>
        <button v-if="!debt.isPaid" class="btn btn-success" @click="pay()">{{ $t('debt.markAsPaid') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: {
      type: Object
    },
    debt: {
      type: Object
    }
  },
  methods: {
    pay () {
      this.$emit('pay', { entity: this.entity, debt: this.debt })
    }
  }
}
</script>
