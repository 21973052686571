import ApiService from './api.service'

const MediaService = {
  upload (assetUuid, type, formData) {
    return ApiService.post('api/v1/game/media/upload', formData)
  },
  uploadAttachment (formData) {
    return ApiService.post('api/v1/game/media/attachment', formData)
  },
  delete (uuid) {
    return ApiService.delete('api/v1/game/media/' + uuid)
  },
  getByType (assetType, assetUuid, type) {
    return ApiService.get('api/v1/game/media/' + assetType + '/' + assetUuid + '/images/' + type)
  },
  deleteByType (assetType, assetUuid, type) {
    return ApiService.delete('api/v1/game/media/' + assetType + '/' + assetUuid + '/images/' + type)
  },
  getFirstImageByType (assetType, assetUuid, type) {
    return ApiService.get('api/v1/game/media/' + assetType + '/' + assetUuid + '/images/' + type + '/first')
  }
}

export default MediaService
