<template>
  <div>
    <div class="row align-items-end">
      <div
        class="col-sm-4 team-table"
        :class="[homeTeamOrderClass, {
          'home-team': teamSelect === 'home'
        }]"
        v-if="config"
      >
        <div
          class="team-period-stats-wrapper mb-4"
        >
          <div class="d-flex align-items-center stat-wrapper">
            <span class="w-100 stat-title">{{ $t('period') }}</span>
            <span
              v-for="tableHeader in config.periods"
              :key="'awayTeamByPeriod-' + tableHeader"
              class="stat"
            >
              {{ tableHeader }}
            </span>
            <span
              v-if="model.homeTeam.by_period_stats.length > model.config.periods"
              class="stat"
            >
              OT
            </span>
          </div>
          <div
            v-for="tableBody in config.live.by_period.team_table" :key="'awayTeamByPeriod-' + tableBody"
            class="d-flex align-items-center stat-wrapper"
          >
            <span class="w-100 stat-title">{{ $t(`live.${tableBody}`) }}</span>
            <span
              v-for="(period, p) in model.homeTeam.by_period_stats"
              :key="'awayTeamByPeriod-' + p"
              class="stat"
            >
              {{ period ? period[tableBody] : '-' }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-sm-4 live-buttons"
        :class="[actionsOrderClass]"
        v-if="config"
      >
        <nav aria-label="Page navigation">
          <ul class="periods pagination pagination-lg justify-content-center">
            <li v-for="period in config.periods" :key="'period_' + period" class="page-item"
                :class="{active: model.current_period === period}">
              <a class="page-link" @click="setPeriod(period)">{{ period }}</a>
            </li>
            <li class="page-item" :class="{active: model.current_period === 0}">
              <a class="page-link" @click="setPeriod(0)">OT</a>
            </li>
          </ul>
        </nav>
        <h4 class="text-center">({{ scoreByPeriod }})</h4>
        <h2 class="text-center">{{ score }}</h2>
      </div>
      <div
        class="col-sm-4 team-table"
        :class="[awayTeamOrderClass, {
          'away-team': teamSelect === 'away'
        }]"
        v-if="config"
      >
        <div
          class="team-period-stats-wrapper mb-4"
        >
          <div class="d-flex align-items-center stat-wrapper">
            <span class="w-100 stat-title">{{ $t('period') }}</span>
            <span
              v-for="tableHeader in config.periods"
              :key="'awayTeamByPeriod-' + tableHeader"
              class="stat"
            >
              {{ tableHeader }}
            </span>
            <span
              v-if="model.awayTeam.by_period_stats.length > model.config.periods"
              class="stat"
            >
              OT
            </span>
          </div>
          <div
            v-for="tableBody in config.live.by_period.team_table" :key="'awayTeamByPeriod-' + tableBody"
            class="d-flex align-items-center stat-wrapper"
          >
            <span class="w-100 stat-title">{{ $t(`live.${tableBody}`) }}</span>
            <span
              v-for="(period, p) in model.awayTeam.by_period_stats"
              :key="'awayTeamByPeriod-' + p"
              class="stat"
            >
              {{ period ? period[tableBody] : '-' }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-sm-4 team-table pt-0 d-flex flex-column"
        :class="[homeTeamOrderClass, {
          'home-team': teamSelect === 'home',
        }]"
        v-if="config"
      >
        <h3 class="text-center unselectable">
          <a @click="selectEntity('team', 'home', model.homeTeam.uuid)"
             v-longpress="(event) => teamEntityClicked(event, 'homeTeam', model.homeTeam)"
             @click.right.prevent="teamEntityClicked($event, 'homeTeam', model.homeTeam)"
             class="btn" style="width: 100%;"
             :style="{ background: homeTeamColor }"
             :class="{'btn-success': relatedTo === 'team' && teamOn === 'home'}">
            {{ model.homeTeam.name }}
          </a>
        </h3>
        <div :class="{'flex-grow-1 align-items-center': model.homeTeam.players?.length}" class="d-flex">
          <ntm-block class="w-100">
            <div class="table-responsive">
              <div v-if="teamSelect === 'home'" class="select-player-wrapper">
                <h3 class="text-white mt-5">{{ playerSelect }}</h3>
                <h3 class="text-white">{{ playerPreview }}</h3>
              </div>
              <div v-if="!model.homeTeam.players?.length" class="row w-100 team-actions">
                <!-- <div class="col-12"><h5 class="text-center">Team actions</h5></div> -->
                <div v-for="(button, n) in buttonTeamGroups" :key="'buttonTeamGroups-' + n" class="action-wrap" :class="[button.class || 'half']">
                  <a class="btn action-button position-relative" :disabled="log.teamOn === '' || relatedTo !== 'team'"
                     :class="{
                'disabled': log.teamOn === '' || relatedTo !== 'team',
                'active': clickedButton === n,
                'has-label': button.hotKey?.label
                }"
                     @click="selectType(n)"
                     :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
                    <span>{{ $t('live.sports.' + config.sport + '.game_log_buttons.team.' + n) }}</span>
                    <span v-if="button.hotKey?.label" class="hot-key-label">{{ button.hotKey?.label }}</span>
                  </a>
                </div>
              </div>
              <table v-if="model.homeTeam && model.homeTeam.players.length > 0"
                     class="table player-live-table table-hover table-vcenter"
              >
                <thead>
                <tr>
                  <th v-if="reversedTeams || !invertedTable"
                      :class="{
                      'right-table-sticky': (!invertedTable && !reversedTeams) || (reversedTeams),
                      'left-table-sticky': invertedTable && !reversedTeams
                    }"
                      class="text-center"
                  >
                    {{ $t('live.table.jersey') }}
                  </th>
                  <th v-show="!reversedTeams && invertedTable" v-for="(column, key) in config.live.player_table"
                      :key="'reversed' + column" class="text-center">
                    {{ key }}
                  </th>
                  <th
                    class="name-col"
                    :class="{
                      'right-table-sticky': (!invertedTable && !reversedTeams) || (reversedTeams),
                      'left-table-sticky': invertedTable && !reversedTeams
                    }"
                  >
                    {{ $t('live.table.name') }}
                  </th>
                  <th v-show="(!reversedTeams && !invertedTable) || (reversedTeams)"
                      v-for="(column, key) in config.live.player_table" :key="'normal' + column"
                      class="text-center">
                    {{ key }}
                  </th>
                  <th
                    v-if="!reversedTeams && invertedTable"
                    class="text-center"
                    :class="{
                      'right-table-sticky': reversedTeams,
                      'left-table-sticky': invertedTable
                    }"
                  >
                    {{ $t('live.table.jersey') }}
                  </th>
                </tr>
                </thead>
                <tbody :class="{'disabled': disableHome}">
                <tr v-for="player in model.homeTeam.players"
                    class="player-row unselectable"
                    :style="{color: autoActionColor('homeTeam', player)}"
                    v-longpress="(event) => playerEntityClicked(event, 'homeTeam', player)"
                    @click.right.prevent="playerEntityClicked($event, 'homeTeam', player)"
                    :class="{'bg-success': log.on === player.uuid,
                    'bg-info': log.teammate === player.uuid,
                    'bg-warning': log.against === player.uuid}"
                    :key="player.uuid" @click="selectEntity('player', 'home', player.uuid)">
                  <td
                    v-if="reversedTeams || !invertedTable"
                    class="font-w600 text-center number-of-jersey"
                    :class="{
                        'bg-success': log.on === player.uuid,
                        'bg-info': log.teammate === player.uuid,
                        'bg-warning': log.against === player.uuid,
                        'right-table-sticky': (!invertedTable && !reversedTeams) || (reversedTeams),
                        'left-table-sticky': invertedTable && !reversedTeams
                      }"
                  >
                    {{
                      autoActionLabel('homeTeam', player) ? autoActionLabel('homeTeam', player) : player.number_of_jersey
                    }}
                  </td>
                  <td v-show="!reversedTeams && invertedTable" v-for="column in config.live.player_table"
                      :key="'reversed' + column + '-' + player.uuid"
                      class="text-muted text-center">
                    {{ player[column] }}
                  </td>
                  <td
                    :class="{
                        'bg-success': log.on === player.uuid,
                        'bg-info': log.teammate === player.uuid,
                        'bg-warning': log.against === player.uuid,
                        'right-table-sticky pr-2': (!invertedTable && !reversedTeams) || (reversedTeams),
                        'left-table-sticky pl-2': invertedTable && !reversedTeams
                      }" class="font-w600 name-col">{{ player.name }}
                  </td>
                  <td v-show="(!reversedTeams && !invertedTable) || (reversedTeams)"
                      v-for="column in config.live.player_table" :key="'normal' + column + '-' + player.uuid"
                      class="text-muted text-center">
                    {{ player[column] }}
                  </td>
                  <td
                    v-if="!reversedTeams && invertedTable"
                    class="font-w600 text-center number-of-jersey"
                    :class="{
                        'bg-success': log.on === player.uuid,
                        'right-table-sticky': !invertedTable,
                        'left-table-sticky': invertedTable
                      }">
                    {{
                      autoActionLabel('homeTeam', player) ? autoActionLabel('homeTeam', player) : player.number_of_jersey
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ntm-block>
        </div>
      </div>
      <div
        class="col-sm-4 live-buttons mb-5 d-flex flex-column"
        :class="[actionsOrderClass]"
        v-if="config"
      >
        <div v-if="model.homeTeam.players?.length && model.awayTeam.players?.length" class="row w-100 team-actions">
          <!-- <div class="col-12"><h5 class="text-center">Team actions</h5></div> -->
          <div v-for="(button, n) in buttonTeamGroups" :key="'buttonTeamGroups-' + n" class="action-wrap" :class="[button.class || 'half']">
            <a class="btn action-button position-relative" :disabled="log.teamOn === '' || relatedTo !== 'team'"
               :class="{
                'disabled': log.teamOn === '' || relatedTo !== 'team',
                'active': clickedButton === n,
                'has-label': button.hotKey?.label
                }"
               @click="selectType(n)"
               :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
              <span>{{ $t('live.sports.' + config.sport + '.game_log_buttons.team.' + n) }}</span>
              <span v-if="button.hotKey?.label" class="hot-key-label">{{ button.hotKey?.label }}</span>
            </a>
          </div>
        </div>
        <div class="d-flex flex-grow-1 align-items-center">
          <div class="row w-100 players-actions">
            <!-- <div class="col-12"><h5 class="text-center">Player actions</h5></div> -->
            <div v-for="(buttonGroup, n) in buttonPlayerGroups" :key="'buttonPlayerGroup-' + n" class="col-sm-12 pb-3">
              <div class="row">
                <div v-for="(button, buttonType) in buttonGroup" :key="'buttonPlayerTypes-' + buttonType"
                     :class="[button.class || 'half']"
                     class="action-wrap">
                  <a class="btn action-button position-relative" :disabled="log.on === '' || relatedTo !== 'player'"
                     :class="{
                      'disabled': log.teamOn === '' || relatedTo !== 'player' || (type !== buttonType && type !== ''),
                      'active': clickedButton === buttonType,
                      'has-label': button.hotKey?.label
                      }"
                     @click="selectType(buttonType)"
                     :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
                    <span>{{
                        $t('live.sports.' + config.sport + '.game_log_buttons.player.' + buttonType)
                      }}</span>
                    <span v-if="button.hotKey?.label"
                          class="hot-key-label">{{ button.hotKey?.label }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-4 team-table pt-0 d-flex flex-column"
        :class="[awayTeamOrderClass, {
          'away-team': teamSelect === 'away',
        }]"
        v-if="config"
      >
        <h3 class="text-center unselectable">
          <a @click="selectEntity('team', 'away', model.awayTeam.uuid)"
             v-longpress="(event) => teamEntityClicked(event, 'awayTeam', model.awayTeam)"
             @click.right.prevent="teamEntityClicked($event, 'awayTeam', model.awayTeam)"
             class="btn"
             :style="{ background: awayTeamColor }"
             :class="{'btn-success': relatedTo === 'team' && teamOn === 'away'}"
             style="width: 100%;">{{ model.awayTeam.name }}
          </a>
        </h3>
        <div :class="{'flex-grow-1 align-items-center': model.awayTeam.players?.length}" class="d-flex">
          <ntm-block class="w-100">
            <div class="table-responsive">
              <div v-if="teamSelect === 'away'" class="select-player-wrapper">
                <h3 class="text-white mt-5">{{ playerSelect }}</h3>
                <h3 class="text-white">{{ playerPreview }}</h3>
              </div>
              <div v-if="!model.awayTeam.players?.length" class="row w-100 team-actions">
                <!-- <div class="col-12"><h5 class="text-center">Team actions</h5></div> -->
                <div v-for="(button, n) in buttonTeamGroups" :key="'buttonTeamGroups-' + n" class="action-wrap" :class="[button.class || 'half']">
                  <a class="btn action-button position-relative" :disabled="log.teamOn === '' || relatedTo !== 'team'"
                     :class="{
                'disabled': log.teamOn === '' || relatedTo !== 'team',
                'active': clickedButton === n,
                'has-label': button.hotKey?.label
                }"
                     @click="selectType(n)"
                     :style="{backgroundColor: button.bgColor ? button.bgColor: '#70b9eb'}">
                    <span>{{ $t('live.sports.' + config.sport + '.game_log_buttons.team.' + n) }}</span>
                    <span v-if="button.hotKey?.label" class="hot-key-label">{{ button.hotKey?.label }}</span>
                  </a>
                </div>
              </div>
              <table v-if="model.awayTeam && model.awayTeam.players.length > 0"
                     class="table player-live-table table-hover table-vcenter "
                     :class="!reversedTeams ? 'right-table' : 'left-table'"
              >
                <thead>
                <tr>
                  <th
                    v-if="!reversedTeams || !invertedTable"
                    class="text-center right-table-sticky"
                  >
                    {{ $t('live.table.jersey') }}
                  </th>
                  <th v-show="reversedTeams && invertedTable" v-for="(column, key) in config.live.player_table"
                      :key="'reversed' + column" class="text-center">
                    {{ key }}
                  </th>
                  <th
                    class="name-col"
                    :class="{
                      'right-table-sticky': (!reversedTeams) || (reversedTeams && !invertedTable),
                      'left-table-sticky': invertedTable && reversedTeams
                    }"
                  >
                    {{ $t('live.table.name') }}
                  </th>
                  <th v-show="(!reversedTeams) || (reversedTeams && !invertedTable)"
                      v-for="(column, key) in config.live.player_table" :key="'normal' + column"
                      class="text-center">
                    {{ key }}
                  </th>
                  <th
                    v-if="reversedTeams && invertedTable"
                    :class="{
                      'right-table-sticky': (!invertedTable && !reversedTeams) || (reversedTeams),
                      'left-table-sticky': invertedTable && reversedTeams
                    }"
                    class="text-center"
                  >
                    {{ $t('live.table.jersey') }}
                  </th>
                </tr>
                </thead>
                <tbody :class="{'disabled': disableAway}">
                <tr v-for="player in model.awayTeam.players"
                    class="player-row unselectable"
                    :style="{color: autoActionColor('awayTeam', player)}"
                    v-longpress="(event) => playerEntityClicked(event, 'awayTeam', player)"
                    @click.right.prevent="playerEntityClicked($event, 'awayTeam', player)"
                    :class="{'bg-success': log.on === player.uuid,
                  'bg-info': log.teammate === player.uuid,
                  'bg-warning': log.against === player.uuid}"
                    :key="player.uuid" @click="selectEntity('player', 'away', player.uuid)">
                  <td v-if="!reversedTeams || !invertedTable"
                      class="right-table-sticky font-w600 text-center number-of-jersey"
                      :class="{
                        'bg-success': log.on === player.uuid,
                        'bg-info': log.teammate === player.uuid,
                        'bg-warning': log.against === player.uuid
                      }">{{
                      autoActionLabel('awayTeam', player) ? autoActionLabel('awayTeam', player) : player.number_of_jersey
                    }}
                  </td>
                  <td v-show="reversedTeams && invertedTable" v-for="column in config.live.player_table"
                      :key="'reversed' + column + player.uuid"
                      class="text-muted text-center">
                    {{ player[column] }}
                  </td>
                  <td
                    :class="{
                      'bg-success': log.on === player.uuid,
                      'bg-info': log.teammate === player.uuid,
                      'bg-warning': log.against === player.uuid,
                      'right-table-sticky pr-2': (!reversedTeams) || (reversedTeams && !invertedTable),
                      'left-table-sticky pl-2': invertedTable && reversedTeams
                    }" class="font-w600 name-col">{{ player.name }}
                  </td>
                  <td v-show="(!reversedTeams) || (reversedTeams && !invertedTable)"
                      v-for="column in config.live.player_table" :key="'normal' + column + player.uuid"
                      class="text-muted text-center">
                    {{ player[column] }}
                  </td>
                  <td v-if="reversedTeams && invertedTable" class="font-w600 text-center number-of-jersey"
                      :class="{
                        'bg-success': log.on === player.uuid,
                        'right-table-sticky': !reversedTeams,
                        'left-table-sticky': reversedTeams
                      }">{{
                      autoActionLabel('awayTeam', player) ? autoActionLabel('awayTeam', player) : player.number_of_jersey
                    }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ntm-block>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center action-wrapper mt-10">
        <button class="btn btn-warning" @click="endGame()">
          {{$t('game.endGame')}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useLive } from '@/views/live/use/useLive'

export default {
  setup () {
    const live = useLive()

    return { ...live }
  },
  data () {
    return {
      homeTeamColor: localStorage.getItem('homeTeamColor') ?? '#70b9eb',
      awayTeamColor: localStorage.getItem('awayTeamColor') ?? '#70b9eb',
      contextMenuTimeout: null
    }
  },
  methods: {
    rerender () {
      this.$forceUpdate()
    }
  }
}

</script>

<style lang="scss" scoped>
.action-button:active,
.action-button.active {
  transition: none;
  box-shadow: none;
  transform: scale(0.90);
}

.player-row {
  color: #575757;
}

.half {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 4px;
}

.full {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 4px;
}

.btn.action-button {
  display: flex;
  justify-content: space-evenly;
  transition: 0.1s !important;
}

.home-team .table-responsive, .away-team .table-responsive {
  position: relative;
}

.home-team .table-responsive .select-player-wrapper, .away-team .table-responsive .select-player-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #0f74a8;
  text-align: center;
  color: white;
  font-size: 1rem;
}

.periods {
  margin-bottom: 30px;
}

.team-table {
  padding-top: 20px;
}

.player-live-table tbody tr {
  cursor: pointer;
}

.player-live-table.table td.number-of-jersey {
  padding: 0.5rem;
  font-size: 16px;
}

.player-live-table tbody tr.selected_player {
  background: #f1f1f1;
}

.player-live-table {
  font-size: 0.7rem;
}

.player-live-table.table th, .player-live-table.table td {
  padding: 0.2rem;
}

.player-live-table.table thead th {
  letter-spacing: 0;
  font-size: 0.7rem;
}

.left-table-sticky {
  position: sticky;
  right: 0;
  background-color: white;
  z-index: 10;
}

.left-table-sticky.name-col {
  right: 25px;
}

.right-table-sticky {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 10;
}

.right-table-sticky.name-col {
  left: 25px;
}

.player-live-table tbody tr:hover .right-table-sticky,
.player-live-table tbody tr:hover .left-table-sticky {
  background-color: #f1f1f1;
}

.player-live-table tbody td {
  white-space: nowrap;
}

.disabled .right-table-sticky,
.disabled .left-table-sticky {
  background-color: #dfdfdf;
}

.live-buttons a {
  width: 100%;
  color: white !important;
  font-size: 0.9rem
}

.live-buttons .periods a {
  color: black !important;
}

tbody.disabled {
  background: #dfdfdf;
}

.side_action_wrapper h3 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.side_action_wrapper h3 {
  min-height: 50px;
}

.accept-log {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);

  &.btn:disabled, &.btn.disabled {
    opacity: 1;
  }
}

.accept-log.disabled {
  background-color: #00b0e8;
}

.team-period-stats-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 1;

  .stat {
    background-color: #fff;
    height: 32px;
    width: 32px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &-wrapper {
      gap: 4px;
    }

    &-title {
      background-color: #fff;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      border-radius: 4px;
    }
  }
}
</style>
