<template>
  <div class="form-group" :class="[ to.inputType, {'formly-has-value': model[field.key], 'formly-has-focus': form[field.key].$active, 'has-error': hasError}]">
    <label v-if="to.label" v-text="$t('formly.fields.' + to.label)"></label>
    <div class="editor-wrapper">
      <ntm-html-editor useCustomImageHandler @image-added="handleImageAdded" v-model="model[field.key]" :placeholder="to.placeholder" ></ntm-html-editor>
      <span class="help-block form-text text-danger"
            v-if="form.$errors[field.key].length > 0"
            v-text="$t('validation.' + field.key + '.' + form.$errors[field.key][0])" />
      <content-loader v-if="this.loading"></content-loader>
    </div>
    <!-- <error-display v-if="form.$errors[field.key].lenght > 0" :form="form" :field="field.key"></error-display> -->
  </div>
</template>

<script>
import baseField from 'vue-formly-bootstrap/src/fields/baseField'
import MediaService from '@/services/media.service'
import ContentLoader from '@/components/layout/ContentLoader'
export default {
  mixins: [baseField],
  data () {
    return {
      loading: false
    }
  },
  components: {
    ContentLoader
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      this.loading = true
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('uuid', this.to.uuid)
      formData.append('assetType', this.to.assetType)
      formData.append('type', 'attachment')

      MediaService.uploadAttachment(formData)
        .then(res => {
          const url = res.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
.editor-wrapper {
  position: relative;
}
</style>
