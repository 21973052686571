import axios from 'axios'

const axiosInstance = axios.create({
  headers: {
    common: {
      Accept: 'application/json'
    },
    post: {
      Accept: 'application/json'
    }
  }
  /* other custom settings */
})

export const TimerService = {

  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init (baseURL) {
    axiosInstance.defaults.baseURL = baseURL
  },

  get (resource) {
    return axiosInstance.get(resource)
  },

  post (resource, data) {
    return axiosInstance.post(resource, data)
  },

  postWithoutLoading (resource, data) {
    return axiosInstance.post(resource, data, { headers: { withoutLoading: true } })
  },

  put (resource, data) {
    return axiosInstance.put(resource, data)
  },

  delete (resource) {
    return axiosInstance.delete(resource)
  },

  customRequest (data) {
    return axiosInstance(data)
  }
}
