<template>
  <li>
    <a class="media py-2" style="cursor: pointer">
      <div v-if="$slots.default" class="mr-3 ml-2 overlay-container overlay-bottom">
        <slot></slot>
      </div>
      <div class="media-body">
        <div class="font-w600" v-text="title"></div>
        <div class="font-w400 text-muted" v-text="subtitle"></div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      items: []
    }
  }
}
</script>
