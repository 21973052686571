<template>
  <!-- Sign In Block -->
  <div class="block block-themed block-fx-shadow mb-0">
    <div class="block-header">
      <h3 class="block-title">Scorekeeper login</h3>
      <div class="block-options">
        <!--<a class="btn-block-option font-size-sm" href="op_auth_reminder.html">Forgot Password?</a>-->
        <!--<a class="btn-block-option" href="op_auth_signup.html" data-toggle="tooltip" data-placement="left" title="New Account">-->
          <!--<i class="fa fa-user-plus"></i>-->
        <!--</a>-->
      </div>
    </div>
    <div class="block-content">
      <div class="p-sm-3 px-lg-4 py-lg-5">
        <!--<h1 class="mb-2">LM Game trucker</h1>-->
        <p>{{$t('enter-code.welcome')}}</p>

        <!-- Sign In Form -->
        <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.min.js which was auto compiled from _es6/pages/op_auth_signin.js) -->
        <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
        <form @submit.prevent="handleSubmit">
          <formly-form :form="form" :model="model" :fields="fields"></formly-form>
          <span v-if="authenticationErrorCode === 401" class="help-block text-danger d-block mb-2">{{ $t('game-code.notValid') }}</span>
          <button type="submit" class="btn btn-block btn-primary">
            <i v-if="!authenticating" class="fa fa-fw fa-sign-in-alt mr-1"></i> <i v-if="authenticating" class="fa fa-fw fa-cog fa-spin"></i> Live
          </button>
        </form>
        <!-- END Sign In Form -->
      </div>
    </div>
  </div>
  <!-- END Sign In Block -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'login',
  data () {
    return {
      model: {
        code: ''
      },
      form: {},
      fields: [
        {
          key: 'code',
          type: 'input',
          required: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', [
      'authenticating',
      'authenticationError',
      'authenticationErrorCode'
    ])
  },
  mounted () {
    const params = (new URL(document.location)).searchParams

    const code = params.get('code')

    if (code) {
      this.model.code = code
      this.handleSubmit()
    }
  },
  methods: {
    ...mapActions('auth', [
      'login'
    ]),
    handleSubmit () {
      // Perform a simple validation that email and password have been typed in
      if (this.model.code !== '') {
        this.login({ code: this.model.code })
      }
    }
  }
}
</script>
