import ApiService from './api.service'

const TeamService = {
  createAndAddNewPlayerToTeam (teamUuid, data) {
    return ApiService.post(`api/v1/game/teams/${teamUuid}/createAndAddNewPlayerToTeam`, data)
  },
  addPlayerToTeam (teamUuid, data) {
    return ApiService.postWithoutLoading(`api/v1/game/teams/${teamUuid}/addPlayerToTeam`, data)
  }
}

export default TeamService
