var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.carousel.pageCount > 1),expression:"carousel.pageCount > 1"}],staticClass:"VueCarousel-pagination"},[_c('ul',{staticClass:"VueCarousel-dot-container",attrs:{"role":"tablist"}},_vm._l((_vm.paginationCount),function(page,index){return _c('li',{key:`${page}_${index}`,staticClass:"VueCarousel-dot",class:{ 'VueCarousel-dot--active': _vm.isCurrentDot(index) },style:(`
        margin-top: ${_vm.carousel.paginationPadding * 2}px;
        padding: ${_vm.carousel.paginationPadding}px;
      `),attrs:{"aria-hidden":"false","role":"presentation","aria-selected":_vm.isCurrentDot(index) ? 'true' : 'false'},on:{"click":function($event){return _vm.goToPage(index)}}},[_c('button',{staticClass:"VueCarousel-dot-button",style:(`
          width: ${_vm.carousel.paginationSize}px;
          height: ${_vm.carousel.paginationSize}px;
          background: ${_vm.isCurrentDot(index) ? _vm.carousel.paginationActiveColor : _vm.carousel.paginationColor};
        `),attrs:{"type":"button","role":"button","aria-label":"`Item ${index}`","title":`Item ${index}`,"tabindex":0}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }