<template>
  <div class="row pb-3">
    <div v-if="!image.src" class="col-sm-6 text-center">
      <img class="pb-4 mw-100"  :src="imageUrl || '/no-image.png'" alt="">
      <button type="button" v-if="imageUrl" @click="deleteImage()" class="btn btn-danger w-100 mb-3">{{
          $t('delete')
        }}
      </button>
    </div>
    <div :class="{'col-sm-12': image.src}" class="col-sm-6">
      <cropper
        v-if="image.src"
        :canvas="{
          height: this.height,
          width: this.width
        }"
        :stencil-component="circle ? 'circle-stencil' : 'rectangle-stencil'"
        :stencil-props="{
          handlers: {},
          movable: false,
          scalable: false,
          aspectRatio: this.aspectRatio,
        }"
        :resize-image="{
          adjustStencil: false
        }"
        :default-size="defaultSize"
        image-restriction="stencil"
        ref="cropper"
        class="upload-example-cropper"
        :src="image.src"
      />
      <div :style="{display: image.src ? 'none' : 'flex'}" class="file-drop-area">
        <span v-if="!loading" class="fake-btn">{{ $t('file.chooseImage') }}</span>
        <span v-if="!loading" class="file-msg">{{ $t('file.orDragAndDrop') }}</span>
        <span v-if="loading" class="upload-cog"><i class="fa fa-cog fa-spin"></i></span>
        <input v-if="!loading" class="file-input" type="file" ref="file" @change="loadImage($event)" accept="image/*"
               capture="environment">
      </div>

      <div class="mt-3">
        <button type="button" v-if="image.src" class="btn btn-success mb-3 mr-3" @click="uploadImage">
          {{ $t('file.cropAndSave') }}
        </button>
        <button type="button" v-if="image.src" class="btn btn-warning mb-3 mr-3" @click="reset">
          <i class="fas fa-undo"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import apiService from '../../services/api.service'
import MediaService from '../../services/media.service'

export default {
  components: {
    Cropper
  },
  props: {
    aspectRatio: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 500
    },
    width: {
      type: Number,
      default: 500
    },
    entityType: {
      type: String,
      default: ''
    },
    entityUuid: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    },
    circle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      imageUrl: '',
      image: {
        src: null,
        type: null
      }
    }
  },
  mounted () {
    this.fetchImage()
  },
  methods: {
    defaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }
      switch (header) {
        case '89504e47':
          return 'png'
        case '47494638':
          return 'gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'jpg'
        default:
          return fallback
      }
    },
    reset () {
      this.image = {
        src: null,
        type: null
      }
    },
    uploadImage () {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        this.loading = true
        const imageType = this.image.type
        this.image = {
          src: null,
          type: null
        }

        const form = new FormData()
        canvas.toBlob(blob => {
          form.append('file', blob, `blob.${imageType}`)
          form.append('type', this.collection)
          form.append('uuid', this.entityUuid)
          form.append('assetType', this.entityType)
          apiService.post(`${process.env.VUE_APP_BASE_URL}api/v1/game/media/upload`, form).then(() => {
            this.fetchAfterChange()
          }).finally(() => {
            this.loading = false
          })
          // You can use axios, superagent and other libraries instead here

          // Perhaps you should add the setting appropriate file format here
        }, `image/${imageType}`)
      }
    },
    loadImage (event) {
      // Reference to the DOM input element
      const { files } = event.target
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //

        // Create a new FileReader to read this image binary data
        const reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, files[0].type)
          }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0])
      }
    },
    fetchImage () {
      return MediaService.getFirstImageByType(this.entityType, this.entityUuid, this.collection).then((res) => {
        this.imageUrl = res.data
        return res.data
      })
    },
    fetchAfterChange () {
      this.fetchImage().then((data) => {
        this.$emit('change', data)
      })
    },
    deleteImage () {
      MediaService.deleteByType(this.entityType, this.entityUuid, this.collection).then(() => {
        this.fetchAfterChange()
      })
    }
  },
  destroyed () {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  }
}
</script>
<style lang="scss">
.file-drop-area {
  background: rgba(112, 185, 235, 0.8);
  height: 100%;
  position: relative;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 3px;
  transition: 0.2s;

  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.upload-cog {
  font-size: 26px;
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  font-weight: 700;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  &:focus {
    outline: none;
  }
}
</style>
