const CODE = 'code'
const REFRESH_CODE = 'refresh_code'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
  getToken () {
    return localStorage.getItem(CODE)
  },

  saveToken (code) {
    localStorage.setItem(CODE, code)
  },

  removeToken () {
    localStorage.removeItem(CODE)
  },

  getRefreshToken () {
    return localStorage.getItem(REFRESH_CODE)
  },

  saveRefreshToken (refreshToken) {
    localStorage.setItem(REFRESH_CODE, refreshToken)
  },

  removeRefreshToken () {
    localStorage.removeItem(REFRESH_CODE)
  }

}

export { TokenService }
